import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import useRoveFocus from "../../../useRoveFocus";
import { MenuButton } from "../../../components";
import SfxExam from "../../../assets/sounds/nl/main_exam.mp3";
import SfxReExam from "../../../assets/sounds/nl/exam_reexam.mp3";
import SfxReExamRetry from "../../../assets/sounds/nl/exam_reexamretry.mp3";
import SfxExamUnavailable from "../../../assets/sounds/nl/exam_unavailable.mp3";
import SfxExamDone from "../../../assets/sounds/nl/exam_done.mp3";
import SfxBack from "../../../assets/sounds/nl/back.mp3";
import useSound from "use-sound";
import { Howl, Howler } from "howler";

const Exams = (props) => {
  let navigate = useNavigate();
  const { history, match, examStatus } = props;
  const authInputRef = useRef(null);
  var soundExamUnavailable = new Howl({ src: SfxExamUnavailable });
  var soundExamDone = new Howl({ src: SfxExamDone });
  var soundExam = new Howl({ src: SfxExam });
  var soundReExam = new Howl({ src: SfxReExam });
  var soundReExamRetry = new Howl({ src: SfxReExamRetry });

  let exam = examStatus.find(({ name }) => name === "Module 20 Examen");
  let reExam = examStatus.find(({ name }) => name === "Module 21 Herexamen ");

  const { path } = match;

  const [playBack] = useSound(SfxBack, {
    interrupt: true,
  });

  const [playExamSound] = useSound(SfxExam, {
    interrupt: true,
  });

  const [playReExamSound] = useSound(SfxReExam, {
    interrupt: true,
  });

  const [focus, setFocus] = useRoveFocus(3);

  useSound(SfxExam, {
    autoplay: true,
  });

  const navigateTo = (path) => {
    navigate(path);
  };

  const playExam = (exam) => {
    switch (exam.status) {
      case "locked":
        soundExamUnavailable.play();
        break;
      case "done":
        soundExamDone.play();
        break;
      case "available":
        soundExam.play();
        break;
      default:
      // code block
    }
  };
  const playReExam = (exam) => {
    Howler.stop();

    switch (exam.status) {
      case "locked":
        soundExamUnavailable.play();
        break;
      case "done":
        soundExamDone.play();
        break;
      case "available":
        soundReExam.play();
        break;
      case "redoable":
        soundReExamRetry.play();
        break;
      default:
      // code block
    }
  };
  const navigateExam = (exam) => {
    Howler.stop();
    switch (exam.status) {
      case "locked":
        soundExamUnavailable.play();
        break;
      case "done":
        soundExamDone.play();
        break;
      case "available":
        navigateTo(`take-exam?chapter_id=${exam.id}`);
        break;
      case "redoable":
        navigateTo(`take-exam?chapter_id=${exam.id}`);
        break;
      default:
      // code block
    }
  };

  const navigateBack = () => {
    Howler.stop();
    // navigate('/');
    history.goBack();
  };

  const onBodyClickHandler = (e) => {
    if (e.target === e.currentTarget) {
      authInputRef.current.focus();
    }
  };

  return (
    <div className="home-container" onClick={onBodyClickHandler}>
      <div>
        <h1>Eindexamens</h1>

        <div className="flex-column">
          <MenuButton
            large
            ref={authInputRef}
            value="Eind examen"
            labelText="Eind examen"
            onClick={() => navigateExam(exam)}
            onFocus={() => playExam(exam)}
            setFocus={setFocus}
            index={0}
            focus={focus === 0}
          />
          <MenuButton
            large
            ref={React.createRef()}
            value="Herexamen"
            labelText="Herexamen"
            onClick={() => navigateExam(reExam)}
            onFocus={() => playReExam(reExam)}
            setFocus={setFocus}
            index={1}
            focus={focus === 1}
          />
          <MenuButton large ref={React.createRef()} value="Terug" labelText="Terug" onClick={navigateBack} onFocus={playBack} setFocus={setFocus} index={2} focus={focus === 2} />
        </div>
      </div>
    </div>
  );
};

export default Exams;
