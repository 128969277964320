import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Cookies from "js-cookie";

import Authentication from "./features/Authentication";
import Dashboard from "./features/Dashboard";

const AppRouter = () => {
  const getInitialRoute = () => {
    const isAuthenticated = Cookies.get("__session");
    return isAuthenticated ? <Dashboard /> : <Navigate to="/login/student_code" />;
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={getInitialRoute()} />
        <Route path="/login/*" element={Authentication()} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
