import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/styles/global.scss";
import AppRouter from "./AppRouter";
import * as serviceWorker from "./serviceWorker";
import packageJson from "../package.json";
global.appVersion = packageJson.version;

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);
root.render(<AppRouter />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
