import React from "react";
import { UnityCore } from "../../../components";

const TakeExam = (props) => {
  let chapterId = new URLSearchParams(props.location.search).get("chapter_id")
  const { history } = props;
  return (
    <div>
      <UnityCore history={history} chapter={chapterId}></UnityCore>
    </div>
  );
};

export default TakeExam;
