import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import useRoveFocus from "../../../useRoveFocus";
import { MenuButton } from "../../../components";
import Sfx1 from "../../../assets/sounds/1.mp3";
import Sfx2 from "../../../assets/sounds/2.mp3";
import Sfx3 from "../../../assets/sounds/3.mp3";
import SfxPracticeExam from "../../../assets/sounds/nl/practice_exam.mp3";
import SfxExamUnavailable from "../../../assets/sounds/nl/exam_unavailable.mp3";
import SfxExamDone from "../../../assets/sounds/nl/exam_done.mp3";
import SfxBack from "../../../assets/sounds/nl/back.mp3";
import useSound from "use-sound";
import { Howl, Howler } from "howler";

const PracticeExams = (props) => {
  let navigate = useNavigate();
  const { history, match, examStatus } = props;
  const authInputRef = useRef(null);
  let practiceExamOne = examStatus.find(({ name }) => name === "Proefexamen 1");
  let practiceExamTwo = examStatus.find(({ name }) => name === "Proefexamen 2");
  let practiceExamThree = examStatus.find(({ name }) => name === "Proefexamen 3");

  const { path } = match;

  var soundExamUnavailable = new Howl({ src: SfxExamUnavailable });
  var soundExamDone = new Howl({ src: SfxExamDone });

  var sound1 = new Howl({ src: Sfx1 });
  var sound2 = new Howl({ src: Sfx2 });
  var sound3 = new Howl({ src: Sfx3 });

  const [playBack] = useSound(SfxBack, {
    interrupt: true,
  });

  const [focus, setFocus] = useRoveFocus(4);

  useSound(SfxPracticeExam, {
    autoplay: true,
  });

  const navigateTo = (path) => {
    navigate(path);
  };

  const playPracticeExam = (practiceExam) => {
    switch (practiceExam.status) {
      case "locked":
        soundExamUnavailable.play();
        break;
      case "done":
        soundExamDone.play();
        break;
      case "available":
        var soundPracticeExam = new Howl({
          src: SfxPracticeExam,
          autoplay: true,
        });

        soundPracticeExam.on("end", function () {
          switch (parseInt(practiceExam.index)) {
            case 1:
              sound1.play();
              break;
            case 2:
              sound2.play();
              break;
            case 3:
              sound3.play();
              break;
            default:
            // code block
          }
        });
        break;
      default:
      // code block
    }
  };

  const navigatePracticeExam = (practiceExam) => {
    Howler.stop();
    switch (practiceExam.status) {
      case "locked":
        soundExamUnavailable.play();
        break;
      case "done":
        soundExamDone.play();
        break;
      case "available":
        navigateTo(`take-exam?chapter_id=${practiceExam.id}`);
        break;
      default:
      // code block
    }
  };

  const navigateBack = () => {
    Howler.stop();
    history.goBack();
  };

  const onBodyClickHandler = (e) => {
    if (e.target === e.currentTarget) {
      authInputRef.current.focus();
    }
  };

  return (
    <div className="home-container" onClick={onBodyClickHandler}>
      <div>
        <h1>Proefexamens</h1>

        <div className="flex-column">
          <MenuButton
            large
            ref={authInputRef}
            value="Proefexamen 1"
            labelText="Proefexamen 1"
            onClick={() => navigatePracticeExam(practiceExamOne)}
            onFocus={() => playPracticeExam(practiceExamOne)}
            setFocus={setFocus}
            index={0}
            focus={focus === 0}
            cssClass={practiceExamOne.status}
          />
          <MenuButton
            large
            ref={React.createRef()}
            value="Proefexamen 2"
            labelText="Proefexamen 2"
            onClick={() => navigatePracticeExam(practiceExamTwo)}
            onFocus={() => playPracticeExam(practiceExamTwo)}
            setFocus={setFocus}
            index={1}
            focus={focus === 1}
            cssClass={practiceExamTwo.status}
          />
          <MenuButton
            large
            ref={React.createRef()}
            value="Proefexamen 3"
            labelText="Proefexamen 3"
            onClick={() => navigatePracticeExam(practiceExamThree)}
            onFocus={() => playPracticeExam(practiceExamThree)}
            setFocus={setFocus}
            index={2}
            focus={focus === 2}
            cssClass={practiceExamThree.status}
          />
          <MenuButton large ref={React.createRef()} value="Terug" labelText="Terug" onClick={navigateBack} onFocus={playBack} setFocus={setFocus} index={3} focus={focus === 3} />
        </div>
      </div>
    </div>
  );
};

export default PracticeExams;
