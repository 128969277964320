import React from "react";
import "../assets/styles/global.scss";

const ExerciseProgress = () => {
  return (
    <div className="exercise-progress">

    </div>
  );
};

export default ExerciseProgress;
