import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useSound from "use-sound";
import { Howl, Howler } from "howler";
import "./authentication.css";

import SfxStart from "../../assets/sounds/nl/login_pw_nr.mp3";
import SfxNumbers from "../../assets/sounds/nl/numbers.mp3";
import SfxBeep from "../../assets/sounds/beep.mp3";
import SfxContinue from "../../assets/sounds/nl/login_space.mp3";

const LoginCode = ({ value, onChangeValue, handleLogin }) => {
  const label = "Inlogcode";
  const [submitDisabled, setSubmitDisabled] = useState("");
  const loginInputRef = useRef(null);
  const loginButtonRef = useRef(null);
  const soundContinue = new Howl({ src: SfxContinue });
  const [playBeep] = useSound(SfxBeep);
  let navigate = useNavigate();

  // eslint-disable-next-line no-unused-vars
  const [play, { stop }] = useSound(SfxStart, {
    autoplay: true,
    interrupt: true,
    onend: () => {
      if (loginInputRef.current) {
        loginInputRef.current.focus();
      }
    },
  });

  const [playNumbers] = useSound(SfxNumbers, {
    interrupt: true,
    sprite: {
      0: [0, 679],
      1: [1000, 679],
      2: [2000, 613],
      3: [3000, 631],
      4: [4000, 531],
      5: [5000, 652],
      6: [6000, 713],
      7: [7000, 746],
      8: [8000, 581],
      9: [9000, 838],
    },
    onend: () => {
      if (loginInputRef.current && loginInputRef.current.value.length === 4) {
        loginButtonRef.current.focus();
        soundContinue.play();
      }
    },
  });

  useEffect(() => {
    setSubmitDisabled(true);
    loginInputRef.current.focus();
    Howler.stop();
  }, []);

  const onKeyDownHandler = (e) => {
    stop();
    Howler.stop();
    if (e.nativeEvent.key === " ") {
      if (loginInputRef.current.value.length === 4) {
        handleLogin();
      }

      e.stopPropagation();
      e.preventDefault();
      return;
    }
  };

  const onChangeHandler = (e) => {
    if (e.nativeEvent.data !== null && e.nativeEvent.data >= 0 && e.nativeEvent.data <= 9) {
      playNumbers({ id: e.nativeEvent.data });
    } else {
      if (e.nativeEvent.data !== null) {
        playBeep();
        return;
      }
    }
    console.log(e.currentTarget.value.length);
    if (e.currentTarget.value.length === 4) {
      setSubmitDisabled(false);
      loginButtonRef.current.focus();
    }
    console.log(submitDisabled);
    onChangeValue(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    handleLogin(navigate);
  };

  const onBodyClickHandler = (e) => {
    if (e.target === e.currentTarget) {
      loginInputRef.current.focus();
    }
  };

  return (
    <div className="center-container" onClick={onBodyClickHandler}>
      <h1>{label}</h1>
      <form className="form-container">
        <input
          ref={loginInputRef}
          className="authentication-input"
          type="text"
          // maxLength="4"
          aria-label={label}
          aria-required="true"
          aria-describedby="inputDescLogin"
          autoComplete="off"
          onChange={onChangeHandler}
          onKeyDown={onKeyDownHandler}
          value={value}
          name={label}
        />
        <span id="inputDescLogin" aria-live="assertive"></span>

        <input
          className="authentication-button"
          ref={loginButtonRef}
          onKeyDown={onKeyDownHandler}
          disabled={submitDisabled}
          type="submit"
          value="Inloggen"
          labeltext="Inloggen"
          onClick={onSubmit}
        />
      </form>
    </div>
  );
};

export default LoginCode;
