import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import withRouter from "../../withRouter";
import useRoveFocus from "../../useRoveFocus";
import SfxSpeedCurrent from "../../assets/sounds/nl/speed_current.mp3";
import SfxSpeed from "../../assets/sounds/nl/settings_speed.mp3";
import SfxBack from "../../assets/sounds/nl/back.mp3";
import Sfx10 from "../../assets/sounds/tien.mp3";
import Sfx20 from "../../assets/sounds/twintig.mp3";
import Sfx30 from "../../assets/sounds/dertig.mp3";
import Sfx40 from "../../assets/sounds/veertig.mp3";
import Sfx50 from "../../assets/sounds/vijftig.mp3";
import SfxSaved from "../../assets/sounds/nl/saved.mp3";
import useSound from "use-sound";
import { Howl } from "howler";
import { MenuButton } from "../../components";

const SpeedSettings = ({ history }) => {
  let navigate = useNavigate();
  const [currentValue, setCurrentValue] = useState(false);
  const [saving, setSaving] = useState(false);
  const KeyValue = window.TypeTopia.KeyValue;
  const [playBack, backSound] = useSound(SfxBack, {
    interrupt: true,
  });
  const [playSaved] = useSound(SfxSaved, {
    interrupt: true,
    onend: () => {
      navigateToSettings();
    },
  });

  var soundSpeedCurrent = new Howl({ src: SfxSpeedCurrent });
  var sound10 = new Howl({ src: Sfx10 });
  var sound20 = new Howl({ src: Sfx20 });
  var sound30 = new Howl({ src: Sfx30 });
  var sound40 = new Howl({ src: Sfx40 });
  var sound50 = new Howl({ src: Sfx50 });

  const [play10] = useSound(Sfx10, {
    interrupt: true,
  });
  const [play20] = useSound(Sfx20, {
    interrupt: true,
  });
  const [play30] = useSound(Sfx30, {
    interrupt: true,
  });
  const [play40] = useSound(Sfx40, {
    interrupt: true,
  });
  const [play50] = useSound(Sfx50, {
    interrupt: true,
  });
  const [focus, setFocus] = useRoveFocus(6);

  useEffect(() => {
    setCurrentValue(KeyValue.get("default_cpm"));
    var currentCpm = KeyValue.get("default_cpm");
    soundSpeedCurrent.on("end", function () {
      switch (parseInt(currentCpm)) {
        case 10:
          sound10.play();
          break;
        case 20:
          sound20.play();
          break;
        case 30:
          sound30.play();
          break;
        case 40:
          sound40.play();
          break;
        case 50:
          sound50.play();
          break;
        default:
        // code block
      }
    });
    new Howl({
      src: SfxSpeed,
      autoplay: true,
      onend: function () {
        soundSpeedCurrent.play();
      },
    });
  }, []);

  const changeDefaultCPM = (str) => {
    setSaving(true);

    setCurrentValue(str);
    api.setKeyValue("default_cpm", str).then((response) => {
      if (response.ok) {
        KeyValue.set("default_cpm", str);
        setCurrentValue(KeyValue.get("default_cpm"));
        console.log("default_cpm ok");
        playSaved();
      }
    });
  };

  const navigateToSettings = () => {
    navigate("/settings");
  };
  const navigateBack = () => {
    backSound.stop();
    navigate("/settings");
  };

  return (
    <div>
      <div className="home-container">
        <div>
          <h1>Snelheid</h1>
          <h1>Huidig: {currentValue} </h1>
          {saving ? (
            <div className="flex-column saving">
              <h1>Opslaan...</h1>
            </div>
          ) : (
            <div className="flex-column">
              <MenuButton
                large
                ref={React.createRef()}
                value="10"
                labelText="10"
                onClick={() => changeDefaultCPM("10")}
                onFocus={play10}
                setFocus={setFocus}
                index={0}
                focus={focus === 0}
              />
              <MenuButton
                large
                ref={React.createRef()}
                value="20"
                labelText="20"
                onClick={() => changeDefaultCPM("20")}
                onFocus={play20}
                setFocus={setFocus}
                index={1}
                focus={focus === 1}
              />
              <MenuButton
                large
                ref={React.createRef()}
                value="30"
                labelText="30"
                onClick={() => changeDefaultCPM("30")}
                onFocus={play30}
                setFocus={setFocus}
                index={2}
                focus={focus === 2}
              />
              <MenuButton
                large
                ref={React.createRef()}
                value="40"
                labelText="40"
                onClick={() => changeDefaultCPM("40")}
                onFocus={play40}
                setFocus={setFocus}
                index={3}
                focus={focus === 3}
              />
              <MenuButton
                large
                ref={React.createRef()}
                value="50"
                labelText="50"
                onClick={() => changeDefaultCPM("50")}
                onFocus={play50}
                setFocus={setFocus}
                index={4}
                focus={focus === 4}
              />
              <MenuButton
                large
                ref={React.createRef()}
                value="Terug"
                labelText="Terug"
                onClick={navigateBack}
                onFocus={playBack}
                setFocus={setFocus}
                index={5}
                focus={focus === 5}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(SpeedSettings);
