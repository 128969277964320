import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { Routes, Route, useNavigate } from "react-router-dom";
import withRouter from "../../withRouter";
import "../../globals";
import api from "../../services/api";
import "./dashboard.css";
import Home from "./Home";
import Course from "./Course";
import Exam from "./Exam";
import ExtraPractice from "./ExtraPractice";
import Settings from "./Settings";
import SpeedSettings from "./SpeedSettings";
import FontSettings from "./FontSettings";
import ColorSettings from "./ColorSettings";
import BackgroundSettings from "./BackgroundSettings";
import SoundSettings from "./SoundSettings";

const Dashboard = ({ history }) => {
  const [loaded, setLoaded] = useState(false);
  let navigate = useNavigate();
  useEffect(() => {
    api.getKeyValues().then((response) => {
      if (response.ok) {
        window.TypeTopia.KeyValue = new window.KeyValueStore(response.data.encoded);
        var colorScheme = window.TypeTopia.KeyValue.get("color_scheme") || 0;
        document.body.classList.add(`color-scheme-${colorScheme}`);
        setLoaded(true);
      } else {
        if (response.status == 401) {
          Cookies.remove("__session");
          navigate("/");
        }
      }
    });
  }, []);
  return (
    <div>
      {loaded ? (
        <Routes>
          <Route exact path={"/*"} element={<Home />} />
          <Route exact path={"/course"} element={<Course />} />
          <Route exact path={"/exam"} element={<Exam />} />
          <Route exact path={"/extra_practice/*"} element={<ExtraPractice />} />
          <Route exact path={"/settings"} element={<Settings />} />
          <Route exact path={"/speed-settings"} element={<SpeedSettings />} />
          <Route exact path={"/font-settings"} element={<FontSettings />} />
          <Route exact path={"/color-settings"} element={<ColorSettings />} />
          <Route exact path={"/background-settings"} element={<BackgroundSettings />} />
          <Route exact path={"/sound-settings"} element={<SoundSettings />} />
        </Routes>
      ) : (
        <div className="center-container">
          <h1>Laden...</h1>
        </div>
      )}
    </div>
  );
};

export default withRouter(Dashboard);
