import React, { useEffect, useCallback } from "react";
import styled from "styled-components";

const KeyboardContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
`

const KeyButton = styled.button`
  font-size: 25px;
  height: 70px;
  width: 70px;
  margin-right: 2px;
  background-color: white;
  border-color: #444;
  border-width: 1px;
`
const VirtualKeyboard = React.forwardRef(({ focus, index, setFocus, onFocus, onClick, onKeySelect, punctuation }, ref) => {
  const keysets = [
    ["q", "w", "e", "r", "t", "y", "u", "i", "o", "p"],
    ["a", "s", "d", "f", "g", "h", "j", "k", "l"],
    ["z", "x", "c", "v", "b", "n", "m"],
  ]

  useEffect(() => {
    if (focus) {
      ref.current.focus();
    }
  }, [ref, focus]);

  const handleSelect = useCallback((e) => {
    if (e.keyCode === 32) {
      // space
      e.preventDefault();
      // onClick();
    }
    setFocus(index);
  }, [index, setFocus, onClick]);

  const punctuationKeys = [".", ",", ";", ":", "!", "?"]

  const onKeyClick = ({ target }) => {
    onKeySelect(target.value)
  }

  const renderLetterRow = (keyset) => {
    return keyset.map((key) => {
      return (
        <KeyButton onClick={onKeyClick} key={key} value={key}>{key}</KeyButton>
      )
    })
  }

  return (
    <KeyboardContainer>
      <div>
        {punctuation ? renderLetterRow(punctuationKeys) : keysets.map((keyset, i) => {
          return (
            <div
              ref={ref}
              onFocus={onFocus}
              onKeyPress={handleSelect}
              key={`${i}-keyset`}
              style={{ marginLeft: i * 15, marginBottom: 2 }}>
              {renderLetterRow(keyset, i)}
            </div>
          )
        })}
      </div>
    </KeyboardContainer>
  )
});

export default VirtualKeyboard;
