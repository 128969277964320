import React from "react";
import withRouter from "../../../withRouter";
import { UnityCore } from "../../../components";

const Letter = (props) => {
  const { history } = props;
  const additionalPractice = {
    capitalize: false,
    practice: "punctuation",
    symbol: "",
    exercise: "basic",
  };
  return (
    <div>
      <UnityCore history={history} additionalPractice={additionalPractice}></UnityCore>
    </div>
  );
};

export default withRouter(Letter);
