var TypeTopia = {

	Core: {
		onInitialized: function ()
		{

		},

		onCompleted: function (results)
		{

		},

		signUp: function (first_name, email, parent_permission, privacy_statement, password, password_confirmation, username)
		{

		},

		prijsvraagAnswer: function (answer)
		{

		},

		toHomePage: function ()
		{
			window.location.assign("https://www.typetopia.com");
		},

		toMissionTwo: function ()
		{
			window.location.assign("https://www.typetopia.com");
		},

		mooButtonPressed: function ()
		{
			console.log("moo");
			window.location.assign("https://www.typetopia.com");
		},

		showPrivacy: function ()
		{
			window.open('https://www.typetopia.com/privacy-statement', '_blank');
		},
	},

	KeyValue: {},
}
export default TypeTopia;
