import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import withRouter from "../../withRouter";
import useRoveFocus from "../../useRoveFocus";
import SfxSetting from "../../assets/sounds/nl/settings_menu.mp3";
import SfxSpeed from "../../assets/sounds/nl/settings_speed.mp3";
import SfxText from "../../assets/sounds/nl/settings_font.mp3";
import SfxColor from "../../assets/sounds/nl/settings_colors.mp3";
import SfxBackground from "../../assets/sounds/nl/settings_background.mp3";
import SfxSound from "../../assets/sounds/nl/sound.mp3";
import SfxBack from "../../assets/sounds/nl/back.mp3";
import useSound from "use-sound";

import { MenuButton } from "../../components";

const Settings = ({ history }) => {
  let navigate = useNavigate();
  const authInputRef = useRef(null);
  const [playSpeed, speedSound] = useSound(SfxSpeed, {
    interrupt: true,
  });
  const [playText, textSound] = useSound(SfxText, {
    interrupt: true,
  });
  const [playColor, colorSound] = useSound(SfxColor, {
    interrupt: true,
  });
  const [playSound, soundSound] = useSound(SfxSound, {
    interrupt: true,
  });
  const [playBackground, backgroundSound] = useSound(SfxBackground, {
    interrupt: true,
  });
  const [playBack, backSound] = useSound(SfxBack, {
    interrupt: true,
  });
  const [focus, setFocus] = useRoveFocus(6);
  useSound(SfxSetting, {
    autoplay: true,
  });

  const navigateTo = (path) => {
    navigate(path);
  };
  const navigateToSpeedSettings = () => {
    speedSound.stop();
    navigateTo("/speed-settings");
  };
  const navigateToFontSettings = () => {
    textSound.stop();
    navigateTo("/font-settings");
  };
  const navigateToColorSettings = () => {
    colorSound.stop();
    navigateTo("/color-settings");
  };
  const navigateToBackgroundSettings = () => {
    backgroundSound.stop();
    navigateTo("/background-settings");
  };
  const navigateToSoundSettings = () => {
    backgroundSound.stop();
    navigateTo("/sound-settings");
  };
  const navigateBack = () => {
    backSound.stop();
    navigate("/");
  };
  const onBodyClickHandler = (e) => {
    if (e.target === e.currentTarget) {
      authInputRef.current.focus();
    }
  };
  return (
    <div>
      <div className="home-container" onClick={onBodyClickHandler}>
        <div>
          <h1>Instellingen</h1>
          <div className="flex-column">
            <MenuButton
              large
              ref={authInputRef}
              value="Snelheid"
              labelText="Snelheid"
              onClick={navigateToSpeedSettings}
              onFocus={playSpeed}
              setFocus={setFocus}
              index={0}
              focus={focus === 0}
            />
            <MenuButton
              large
              ref={React.createRef()}
              value="Lettergrootte"
              labelText="Lettergrootte"
              onClick={navigateToFontSettings}
              onFocus={playText}
              setFocus={setFocus}
              index={1}
              focus={focus === 1}
            />
            <MenuButton
              large
              ref={React.createRef()}
              value="Kleurenschema"
              labelText="Kleurenschema"
              onClick={navigateToColorSettings}
              onFocus={playColor}
              setFocus={setFocus}
              index={2}
              focus={focus === 2}
            />
            <MenuButton
              large
              ref={React.createRef()}
              value="Achtergrond"
              labelText="Achtergrond"
              onClick={navigateToBackgroundSettings}
              onFocus={playBackground}
              setFocus={setFocus}
              index={3}
              focus={focus === 3}
            />
            <MenuButton
              large
              ref={React.createRef()}
              value="Geluid"
              labelText="Geluid"
              onClick={navigateToSoundSettings}
              onFocus={playSound}
              setFocus={setFocus}
              index={4}
              focus={focus === 4}
            />
            <MenuButton large ref={React.createRef()} value="Terug" labelText="Terug" onClick={navigateBack} onFocus={playBack} setFocus={setFocus} index={5} focus={focus === 5} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Settings);
