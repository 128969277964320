import React, { useState, useEffect } from "react";
import withRouter from "../../../withRouter";
import { Routes, Route, useMatch } from "react-router-dom";

import api from "../../../services/api";
import Menu from "./Menu";
import PracticeExams from "../Exam/PracticeExams";
import Exams from "../Exam/Exams";
import TakeExam from "./TakeExam";

const Exam = () => {
  const { path } = useMatch();
  const [loaded, setLoaded] = useState(false);
  const [examStatus, setExamStatus] = useState(false);

  useEffect(() => {
    api.getExams().then((response) => {
      if (response.ok) {
        setExamStatus(response.data);
        setLoaded(true);
      } else {
        if (response.status == 401) {
          // Cookies.remove("__session");
          // history.replace("/");
        }
      }
    });
  }, []);
  return (
    <div>
      {loaded ? (
        <Routes>
          <Route path={path} exact render={(props) => <Menu {...props} examStatus={examStatus} />} />
          <Route path={`${path}/practice-exams`} render={(props) => <PracticeExams {...props} examStatus={examStatus} />} />
          <Route path={`${path}/exams`} render={(props) => <Exams {...props} examStatus={examStatus} />} />
          <Route path={`${path}/take-exam`} render={(props) => <TakeExam {...props} examStatus={examStatus} />} />
        </Routes>
      ) : (
        <div className="center-container">
          <h1>Laden...</h1>
        </div>
      )}
    </div>
  );
};

export default withRouter(Exam);
