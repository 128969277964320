import React from "react";
import withRouter from "../../withRouter";
import { UnityCore, ExerciseProgress } from "../../components";

const Course = ({ history }) => {
  return (
    <div>
      <ExerciseProgress></ExerciseProgress>
      <UnityCore history={history}></UnityCore>
    </div>
  );
};

export default withRouter(Course);
