var KeyValueStore = class KeyValueStore {

	constructor(encodedStore){
		this.store = JSON.parse(this.b64DecodeUnicode(encodedStore));;
  }
  get(key) {
    return this.store[key];
	}
	set(key, value) {
		this.store[key] = value;
  }
  b64DecodeUnicode(str) {
    return decodeURIComponent(
      atob(str)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join("")
    )
  }
};
export default KeyValueStore;
