import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Unity, useUnityContext } from "react-unity-webgl";
import { Howl } from "howler";
import ProgressBar from "../components/ProgressBar";
import api from "../services/api";
import "../assets/styles/global.scss";
import SfxLoading from "../assets/sounds/nl/loading.mp3";
import SfxStarting from "../assets/sounds/nl/exercise_starting.mp3";

const UnityCore = (props) => {
  let navigate = useNavigate();
  const [playerJson, setPlayerJson] = useState("");
  const [payload, setPayload] = useState({});
  const [pointer, setPointer] = useState("");
  const [unityLoaded, setUnityLoaded] = useState(false);
  const soundLoading = new Howl({ src: SfxLoading });
  const soundStarting = new Howl({ src: SfxStarting });
  const additionalPractice = props.additionalPractice || false;
  const chapterId = props.chapter || null;
  const labelStyles = {
    padding: 5,
    color: "white",
    fontWeight: "bold",
    lineHeight: "50px",
    fontSize: 24,
  };

  let loadingInterval = "";

  const { unityProvider, UNSAFE__unityInstance, loadingProgression, isLoaded, sendMessage, addEventListener, removeEventListener } = useUnityContext({
    loaderUrl: `${process.env.PUBLIC_URL}/player_build/WebGL.loader.js`,
    dataUrl: `${process.env.PUBLIC_URL}/player_build/WebGL.data`,
    frameworkUrl: `${process.env.PUBLIC_URL}/player_build/WebGL.framework.js`,
    codeUrl: `${process.env.PUBLIC_URL}/player_build/WebGL.wasm`,
    companyName: "TopiaTeam",
    productName: "TypeTopia VIP",
    productVersion: "1.0",
  });

  useEffect(() => {
    console.log("loadingProgression");
    return () => {
      console.log(loadingProgression);
    };
  }, [loadingProgression]);

  useEffect(() => {
    if (isLoaded && !unityLoaded && playerJson !== "") {
      setUnityLoaded(true);
      sendMessage("CoreObject/AudioMgr", "VolumeOff");
      sendMessage("CoreObject/AudioMgr", "VolumeOn");
      clearInterval(loadingInterval);
      loadedUnity();
      console.log("loadedUnity");
    } else {
      console.warn("not loaded");
    }
    return () => {
      console.log("isLoaded");
      console.log(isLoaded);
    };
  }, [isLoaded, playerJson, unityProvider]);

  useEffect(() => {
    return () => {
      console.log(unityProvider);
    };
  }, [unityProvider]);

  useEffect(() => {
    soundStarting.play();
    loadingInterval = setInterval(() => {
      soundLoading.play();
    }, 10000);
    api.player().then((response) => {
      if (response.ok) {
        setPlayerJson(response.data);
      }
    });
  }, []);

  useEffect(() => {
    if (isLoaded && playerJson !== "") {
      sendMessage("CoreObject", "globalAssets", JSON.stringify({ globalAssets: playerJson.globalAssets }));
    }
  }, [playerJson, isLoaded]);

  useEffect(() => {
    if (UNSAFE__unityInstance) {
      window.unityInstance = UNSAFE__unityInstance;
    }
  }, [UNSAFE__unityInstance]);

  const loadedUnity = () => {
    console.log("loadedUnity!!!");
    api.child().then((response) => {
      if (response.ok) {
        setPointer(response.data.pointer);
        sendMessage(
          "CoreObject",
          "env",
          JSON.stringify({
            env: "development",
            productUuid: response.data.product_uuid,
            postFpsData: false,
            color0: response.data.color0,
            color1: response.data.color1,
            color2: response.data.color2,
            color3: response.data.color3,
            fontSize: response.data.font_size,
            blurAmount: response.data.blur_amount,
            desaturationAmount: response.data.desaturation_amount,
            applyColor: response.data.apply_color,
            defaultCpm: response.data.default_cpm,
            globalVolume: 1,
            musicVolume: response.data.background_sound ? 0.15 : 0,
            ambientVolume: 0.15,
            uiVolume: 1,
            wordsVolume: 1,
            sfxVolume: 0.33,
          })
        );

        if (additionalPractice) {
          var payload = additionalPractice;

          api
            .playerInitialized("/child/exercise/initialized?capitalize=true&exercise=basic&practice=problematic", {
              chapter: response.data.current_chapter,
              payload: payload,
            })
            .then((response) => {
              if (response.ok) {
                handleResponse(response.data);
              }
            });
        } else {
          let chapter = chapterId || response.data.current_chapter;
          console.log("/child/exercise/initialized");
          api
            .playerInitialized("/child/exercise/initialized", {
              chapter: chapter,
            })
            .then((response) => {
              if (response.ok) {
                handleResponse(response.data);
              }
            });
        }
      }
    });
  };

  const focusOnPlayer = () => {
    console.log("focusOnPlayer");
  };

  const handleBackClick = () => {
    navigate("/");
  };

  const handleInitialized = useCallback(() => {
    console.log("handleInitialized");
  }, []);

  const handleReadyToPlay = useCallback(() => {
    clearInterval(loadingInterval);
    console.log("onReadyToPlay");
  }, []);

  const handleExerciseFailed = useCallback(() => {
    console.log("handleExerciseFailed");
  }, []);

  const handleQuit = useCallback(() => {
    console.log("handleQuit");
  }, []);

  const handleCompleted = useCallback((result) => {
    console.log("handleCompleted");
    var parsedResult = JSON.parse(result);
    var data = JSON.stringify({ data: parsedResult, payload: payload });
    var encoded = b64EncodeUnicode(data);
    api
      .playerCompleted("/child/exercise/completed", encoded)
      .then((data) => {
        console.log(data);
        if (parsedResult.redirectToOverview) {
          this.goToOverview();
        } else {
          var delegateToUnity, ref;
          delegateToUnity = (ref = result.type) === "endOfDay" || ref === "goToOverview";

          handleResponse(data.data, delegateToUnity);
        }
      })
      .catch((error) => {
        console.log(data);
        console.log("handleServerError called from onCompleted");
        handleServerError(error);
      });
  }, []);

  const b64EncodeUnicode = (str) => {
    return btoa(
      encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
        return String.fromCharCode("0x" + p1);
      })
    );
  };

  const b64DecodeUnicode = (str) => {
    return decodeURIComponent(
      atob(str)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
  };

  const handleResponse = (data, delegateToUnity) => {
    if (delegateToUnity === undefined || delegateToUnity === null) {
      delegateToUnity = false;
    }
    data = JSON.parse(b64DecodeUnicode(data.encoded));
    setPayload(data.payload);
    setPointer(data.progress.pointer);

    if (data.segment != null) {
      window.unityInstance.SendMessage("CoreObject", "userData", JSON.stringify(data.user));
      if (!delegateToUnity) {
        window.unityInstance.SendMessage("CoreObject", "start", JSON.stringify(data.segment));
      }
    } else {
      console.log("no segment");
    }
  };

  const goToOverview = (reload = false) => {
    navigate(reload ? "/?reload=true" : "/");
  };

  const handleServerError = (xhr) => {
    if (xhr.getResponseHeader("goToOverview") == "true") {
      navigate("/");
    }
  };

  useEffect(() => {
    addEventListener("onInitialized", handleInitialized);
    addEventListener("onReadyToPlay", handleReadyToPlay);
    addEventListener("onExerciseFailed", handleExerciseFailed);
    addEventListener("onQuit", handleQuit);
    addEventListener("onCompleted", handleCompleted);
    return () => {
      removeEventListener("onInitialized", handleInitialized);
      removeEventListener("onReadyToPlay", handleReadyToPlay);
      removeEventListener("onExerciseFailed", handleExerciseFailed);
      removeEventListener("onQuit", handleQuit);
      removeEventListener("onCompleted", handleCompleted);
    };
  }, [addEventListener, removeEventListener, handleReadyToPlay]);

  return (
    <div className="flex-container" onClick={focusOnPlayer}>
      <div className="container">
        {loadingProgression !== 1 && (
          <div>
            <span
              ref={(input) => input && input.focus()}
              role="progressbar"
              aria-valuenow={Math.round(loadingProgression * 100)}
              aria-live="polite"
              aria-valuemin="0"
              aria-valuemax="100"
              tabIndex="-1"
              className="progress-input color-scheme-border"
              style={labelStyles}
            >
              {Math.round(loadingProgression * 100)}
            </span>
            <ProgressBar bgcolor={"#000000"} completed={Math.round(loadingProgression * 100)} />
          </div>
        )}

        {!isLoaded && <div className="unity-container color-scheme-border"> loading</div>}
        <Unity
          unityProvider={unityProvider}
          className="unity-container color-scheme-border"
          style={{ visibility: isLoaded ? "visible" : "hidden", width: 800, height: 600 }}
          disabledCanvasEvents={["dragstart", "scroll"]}
          devicePixelRatio={window.devicePixelRatio}
          matchWebGLToCanvasSize={false}
          modules={playerJson}
        />

        <div className="flex">
          <div>
            <input
              className="button-medium color-scheme-border mb-0"
              type="submit"
              value="Terug naar het overzicht"
              onClick={() => {
                handleBackClick();
              }}
            />
          </div>
          <span>Pointer: {pointer}</span>
        </div>
      </div>
    </div>
  );
};

export default UnityCore;
