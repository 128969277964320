import apisauce from "apisauce";
import Cookies from "js-cookie";

// export const BASE_URL = "http://localhost:3002";
export const BASE_URL = process.env.REACT_APP_BASE_URL
  ? process.env.REACT_APP_BASE_URL
  : "http://localhost:3002";

const create = (baseURL: string = `${BASE_URL}/api/v1/`) => {
  const api = apisauce.create({
    baseURL,
    timeout: 15000,
  });

  // Set the header AuthorizationToken to the user token if any
  api.addAsyncRequestTransform(request => async () => {

    const authorizationToken = Cookies.get("__session");

    if (authorizationToken) {
      request.headers.token = authorizationToken;
    }
  });
  api.addRequestTransform(request => {

    const authorizationToken = Cookies.get("__session");

    if (authorizationToken) {
      request.headers.token = authorizationToken;
    }
  })

  const authenticate = (userCode, passCode) => {
    return api.post("authentications", {
      user_code: userCode,
      pass_code: passCode,
    });
  };
  const player = () => {
    return api.get("unity");
  };
  const child = () => {
    return api.get("/child");
  };
  const playerInitialized = (initializedPath, params) => {
    return api.get(initializedPath, params);
  };
  const playerCompleted = (completedPath, encoded) => {
    let formdata = new FormData();
    formdata.append("encoded", encoded);
    return api.post(completedPath, formdata, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  };
  const setKeyValue = (key, value) => {
    let formdata = new FormData();
    formdata.append("key", key);
    formdata.append("value", value);
    return api.put("/child/key_value_store", formdata, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  };
  const setKeysAndValues = (keysAndValues) => {
    let formdata = new FormData();
    formdata.append("keysandvalues", JSON.stringify(keysAndValues));
    return api.put("/child/key_value_store", formdata, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  };
  const getKeyValues = () => {
    return api.get("/child/key_value_store");
  };
  const getAdditionalPractice = () => {
    return api.get("/child/exercise/practice");
  };
  const getExams = () => {
    return api.get("/child/home/optional_chapters");
  };
  return {
    authenticate,
    player,
    child,
    playerInitialized,
    playerCompleted,
    getExams,
    setKeyValue,
    setKeysAndValues,
    getKeyValues,
    getAdditionalPractice,
  };
};

const api = create();

export default api;
