import React, { useEffect, useCallback } from "react";

import "../assets/styles/global.scss";

const MenuSlider = React.forwardRef(({ labelText, value, onClick, large, type, focus, index, setFocus, onFocus, onChange }, ref) => {

  useEffect(() => {
    if (focus) {
      ref.current.focus();
    }
  }, [ref, focus]);
  const handleSelect = useCallback((e) => {
    if (e.charCode === 32) {

      e.preventDefault();
      onClick(e);
    }
    setFocus(index);
  }, [index, setFocus, onClick]);
  return (
    <input
      className={"color-scheme-border"}
      tabIndex={focus ? 0 : -1}
      type={"range"}
      ref={ref}
      value={value}
      min="0"
      max="1"
      step=".25"
      onClick={onClick}
      onKeyPress={handleSelect}
      onChange={onChange}
      onFocus={onFocus}
    />
  );
});

export default MenuSlider;
