import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import withRouter from "../../withRouter";
import useRoveFocus from "../../useRoveFocus";
import SfxColorsCurrent from "../../assets/sounds/nl/colors_current.mp3";
import SfxColors from "../../assets/sounds/nl/colors_menu.mp3";
import SfxSaved from "../../assets/sounds/nl/saved.mp3";
import SfxLight from "../../assets/sounds/nl/schema_light.mp3";
import SfxDark from "../../assets/sounds/nl/schema_dark.mp3";
import SfxAlt from "../../assets/sounds/nl/schema_alternative.mp3";
import SfxBack from "../../assets/sounds/nl/back.mp3";
import useSound from "use-sound";
import { Howl, Howler } from "howler";
import { MenuButton } from "../../components";

const ColorSettings = ({ history }) => {
  let navigate = useNavigate();
  const KeyValue = window.TypeTopia.KeyValue;
  const [currentColorSchemeValue, setCurrentColorSchemeValue] = useState(false);
  const [saving, setSaving] = useState(false);
  const [playBack, backSound] = useSound(SfxBack, {
    interrupt: true,
  });
  const [playLight] = useSound(SfxLight, {
    interrupt: true,
  });
  const [playDark] = useSound(SfxDark, {
    interrupt: true,
  });
  const [playAlt] = useSound(SfxAlt, {
    interrupt: true,
  });
  const [playSaved] = useSound(SfxSaved, {
    interrupt: true,
    onend: () => {
      navigateToSettings();
    },
  });

  const [focus, setFocus] = useRoveFocus(4);
  const navigateToSettings = () => {
    navigate("/settings");
  };

  var soundColorsCurrent = new Howl({ src: SfxColorsCurrent });
  var soundColors = new Howl({ src: SfxColors });
  var soundLight = new Howl({ src: SfxLight });
  var soundDark = new Howl({ src: SfxDark });
  var soundAlt = new Howl({ src: SfxAlt });

  useEffect(() => {
    setCurrentColorSchemeValue(parseInt(KeyValue.get("color_scheme") || 0));
    var currentColorScheme = KeyValue.get("color_scheme");

    console.log(currentColorScheme);
    soundColors.play();
    soundColorsCurrent.on("end", function () {
      if (currentColorScheme === 0) {
        soundLight.play();
      } else if (currentColorScheme === 1) {
        soundDark.play();
      } else {
        soundAlt.play();
      }
    });
    soundColors.on("end", function () {
      soundColorsCurrent.play();
    });
  }, []);

  const handleCurrentColorScheme = () => {
    var currentColorScheme = KeyValue.get("color_scheme");
    document.body.classList.remove("color-scheme-0");
    document.body.classList.remove("color-scheme-1");
    document.body.classList.remove("color-scheme-2");

    document.body.classList.add(`color-scheme-${currentColorScheme}`);
    playBack();
  };

  const handleFocusChangeColorScheme = (colorScheme) => {
    Howler.stop();
    document.body.classList.remove("color-scheme-0");
    document.body.classList.remove("color-scheme-1");
    document.body.classList.remove("color-scheme-2");

    document.body.classList.add(`color-scheme-${colorScheme}`);
    switch (colorScheme) {
      case 0:
        playLight();
        break;
      case 1:
        playDark();
        break;
      case 2:
        playAlt();
        break;
      default:
      // code block
    }
  };

  const changeColorScheme = (colorScheme) => {
    setSaving(true);
    setCurrentColorSchemeValue(colorScheme);
    var color0, color1, color2, color3;
    switch (colorScheme) {
      case 0:
        color0 = "#FFFFFF";
        color1 = "#000000";
        color2 = "#F7A62F";
        color3 = "#FFFF12";

        break;
      case 1:
        color0 = "#000000";
        color1 = "#FFFFFF";
        color2 = "#000099";
        color3 = "#139914";
        break;
      case 2:
        color0 = "#000099";
        color1 = "#FFFF12";
        color2 = "#FF0317";
        color3 = "#80110B";

        break;
      default:
      // code block
    }
    api.setKeysAndValues({ color_scheme: colorScheme, color0: color0, color1: color1, color2: color2, color3: color3 }).then((response) => {
      if (response.ok) {
        KeyValue.set("color_scheme", colorScheme);
        KeyValue.set("color0", color0);
        KeyValue.set("color1", color1);
        KeyValue.set("color2", color2);
        KeyValue.set("color3", color3);
        playSaved();
      }
    });
  };
  const navigateBack = () => {
    backSound.stop();
    navigate("/settings");
  };
  return (
    <div>
      <div className="home-container">
        <div>
          <h1>Kleuren</h1>
          <h1>Huidig: {parseInt(currentColorSchemeValue) === 0 ? "Licht" : parseInt(currentColorSchemeValue) === 1 ? "Donker" : "Alternatief"} </h1>
          {saving ? (
            <div className="flex-column saving">
              <h1>Opslaan...</h1>
            </div>
          ) : (
            <div className="flex-column">
              <MenuButton
                large
                ref={React.createRef()}
                labelText="Licht"
                value="Licht"
                onClick={() => changeColorScheme(0)}
                onFocus={() => handleFocusChangeColorScheme(0)}
                setFocus={setFocus}
                index={0}
                focus={focus === 0}
              />

              <MenuButton
                large
                ref={React.createRef()}
                value="Donker"
                labelText="Donker"
                onClick={() => changeColorScheme(1)}
                onFocus={() => handleFocusChangeColorScheme(1)}
                setFocus={setFocus}
                index={1}
                focus={focus === 1}
              />
              <MenuButton
                large
                ref={React.createRef()}
                value="Alternatief"
                labelText="Alternatief"
                onClick={() => changeColorScheme(2)}
                onFocus={() => handleFocusChangeColorScheme(2)}
                setFocus={setFocus}
                index={2}
                focus={focus === 2}
              />
              <MenuButton
                large
                ref={React.createRef()}
                value="Terug"
                labelText="Terug"
                onClick={navigateBack}
                onFocus={handleCurrentColorScheme}
                setFocus={setFocus}
                index={3}
                focus={focus === 3}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(ColorSettings);
