import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import useRoveFocus from "../../../useRoveFocus";
import { MenuButton } from "../../../components";
import SfxRealExam from "../../../assets/sounds/nl/exam_real.mp3";
import SfxPracticeExam from "../../../assets/sounds/nl/practice_exam.mp3";
import SfxBack from "../../../assets/sounds/nl/back.mp3";
import SfxExam from "../../../assets/sounds/nl/main_exam.mp3";
import useSound from "use-sound";
import { Howl, Howler } from "howler";

const Menu = (props) => {
  let navigate = useNavigate();
  const authInputRef = useRef(null);
  const { history, match } = props;

  const { path } = match;

  const [playBack] = useSound(SfxBack, {
    interrupt: true,
  });

  const [playExam] = useSound(SfxExam, {
    interrupt: true,
  });

  const [playRealExam] = useSound(SfxRealExam, {
    interrupt: true,
  });
  const [focus, setFocus] = useRoveFocus(3);

  useSound(SfxExam, {
    autoplay: true,
  });

  const navigateTo = (path) => {
    navigate(path);
  };

  const playPracticeExams = () => {
    new Howl({
      src: SfxPracticeExam,
      autoplay: true,
    });
  };

  const navigatePracticeExams = () => {
    navigateTo(`${path}/practice-exams`);
  };
  const navigateExams = () => {
    navigateTo(`${path}/exams`);
  };

  const navigateBack = () => {
    Howler.stop();
    navigate("/");
  };

  const onBodyClickHandler = (e) => {
    if (e.target === e.currentTarget) {
      authInputRef.current.focus();
    }
  };

  return (
    <div className="home-container" onClick={onBodyClickHandler}>
      <div>
        <h1>Examens</h1>
        <div className="flex-column">
          <MenuButton
            large
            ref={authInputRef}
            value="Proefexamens"
            labelText="Proefexamens"
            onClick={navigatePracticeExams}
            onFocus={playPracticeExams}
            setFocus={setFocus}
            index={0}
            focus={focus === 0}
          />
          <MenuButton
            large
            ref={React.createRef()}
            value="Eindexamens"
            labelText="Eindexamens"
            onClick={navigateExams}
            onFocus={playRealExam}
            setFocus={setFocus}
            index={1}
            focus={focus === 1}
          />
          <MenuButton large ref={React.createRef()} value="Terug" labelText="Terug" onClick={navigateBack} onFocus={playBack} setFocus={setFocus} index={2} focus={focus === 2} />
        </div>
      </div>
    </div>
  );
};

export default Menu;
