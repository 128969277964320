import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import withRouter from "../../withRouter";
import useRoveFocus from "../../useRoveFocus";
import { MenuButton } from "../../components";
import SfxTextCurrent from "../../assets/sounds/nl/sound_current.mp3";
import SfxSound from "../../assets/sounds/nl/sound.mp3";
import SfxOn from "../../assets/sounds/nl/on.mp3";
import SfxOff from "../../assets/sounds/nl/off.mp3";
import SfxBack from "../../assets/sounds/nl/back.mp3";
import SfxSaved from "../../assets/sounds/nl/saved.mp3";
import useSound from "use-sound";
import { Howl, Howler } from "howler";

const SoundSettings = ({ history }) => {
  let navigate = useNavigate();
  const [currentValue, setCurrentValue] = useState(false);
  const [saving, setSaving] = useState(false);

  const [playBack] = useSound(SfxBack, {
    interrupt: true,
  });
  const [playSaved] = useSound(SfxSaved, {
    interrupt: true,
    onend: () => {
      navigateToSettings();
    },
  });

  const [focus, setFocus] = useRoveFocus(3);
  const authInputRef = useRef(null);
  const KeyValue = window.TypeTopia.KeyValue;
  var soundTextCurrent = new Howl({ src: SfxTextCurrent });

  var soundOn = new Howl({ src: SfxOn });
  var soundOff = new Howl({ src: SfxOff });

  useEffect(() => {
    soundTextCurrent.on("end", function () {
      if (KeyValue.get("background_sound").toString() === "true") {
        soundOn.play();
      } else {
        soundOff.play();
      }
    });
    var soundText = new Howl({
      src: SfxSound,
      autoplay: true,
      onend: function () {
        soundTextCurrent.play();
      },
    });
  }, []);

  useEffect(() => {
    setCurrentValue(KeyValue.get("background_sound"));
  }, [currentValue]);

  const backgroundSoundFocus = (backgroundSoundValue) => {
    Howler.stop();
    if (backgroundSoundValue.toString() === "true") {
      soundOn.play();
    } else {
      soundOff.play();
    }
  };

  const toggleBackgroundSound = (str) => {
    setSaving(true);
    KeyValue.set("background_sound", str);
    setCurrentValue(str);
    api.setKeyValue("background_sound", str).then((response) => {
      if (response.ok) {
        KeyValue.set("background_sound", str);
        console.log("change background_sound ok");
        playSaved();
      }
    });
  };
  const navigateToSettings = () => {
    Howler.stop();
    navigate("/settings");
  };
  const navigateBack = () => {
    Howler.stop();
    navigate("/settings");
  };

  return (
    <div>
      <div className="home-container">
        <div>
          <h1>Geluid</h1>

          {saving ? (
            <div className="flex-column saving">
              <h1>Opslaan...</h1>
            </div>
          ) : (
            <div className="flex-column">
              <h1>Achtergrond geluid</h1>
              <h2>Huidig: {currentValue.toString() === "true" ? "Aan" : "Uit"}</h2>
              <MenuButton
                large
                ref={authInputRef}
                value="Aan"
                labelText="Aan"
                onClick={() => toggleBackgroundSound(true)}
                onFocus={() => backgroundSoundFocus(true)}
                setFocus={setFocus}
                index={0}
                focus={focus === 0}
              />
              <MenuButton
                large
                ref={React.createRef()}
                value="Uit"
                labelText="Uit"
                onClick={() => toggleBackgroundSound(false)}
                onFocus={() => backgroundSoundFocus(false)}
                setFocus={setFocus}
                index={1}
                focus={focus === 1}
              />
              <MenuButton
                large
                ref={React.createRef()}
                value="Terug"
                labelText="Terug"
                onClick={navigateBack}
                onFocus={playBack}
                setFocus={setFocus}
                index={2}
                focus={focus === 2}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(SoundSettings);
