import React, { useEffect, useCallback } from "react";

import "../assets/styles/global.scss";

const KeyButton = React.forwardRef(({ labelText, value, onClick, large, type, focus, index, setFocus, onFocus }, ref) => {

  useEffect(() => {
    if (focus) {
      ref.current.focus();
    }
  }, [ref, focus]);
  const handleSelect = useCallback((e) => {
    if (e.keyCode === 32) {
      // space
      e.preventDefault();
      onClick();
    }
    setFocus(index);
  }, [index, setFocus, onClick]);
  return (
    <input
      className={"button-small color-scheme-border"}
      tabIndex={focus ? 0 : -1}
      type={type || "button"}
      value={labelText}
      ref={ref}
      onClick={onClick}
      onKeyPress={handleSelect}
      onFocus={onFocus}
    />
  );
});

export default KeyButton;
