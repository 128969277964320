import React, { useCallback } from "react";

import "../assets/styles/global.scss";

const Button = React.forwardRef(({ labelText, value, onClick, large, type, focus, index, onFocus }, ref) => {

  const handleSelect = useCallback((e) => {
    if (e.keyCode === 32) {
      // space
      e.preventDefault();
      onClick();
    }
  }, [onClick]);
  return (
    <input
      className={large ? "button-large color-scheme-border" : "button-small"}
      tabIndex={focus ? 0 : -1}
      type={type || "button"}
      value={value}
      ref={ref}
      onClick={onClick}
      onKeyPress={handleSelect}
      onFocus={onFocus}
    />
  );
});

export default Button;
