import React, { useEffect, useCallback } from "react";

import "../assets/styles/global.scss";

const MenuButton = React.forwardRef(({ labelText, value, onClick, large, type, focus, index, setFocus, onFocus, cssClass = undefined}, ref) => {

  useEffect(() => {
    if (focus) {
      ref.current.focus();
    }
  }, [ref, focus]);
  const handleSelect = useCallback((e) => {
    if (e.keyCode === 32) {
      // space
      e.preventDefault();
      onClick();
    }
    setFocus(index);
  }, [index, setFocus, onClick]);
  return (
    <input
      className={large ? `button-large color-scheme-border ${cssClass}` : "button-small"}
      tabIndex={focus ? 0 : -1}
      type={type || "button"}
      value={labelText}
      ref={ref}
      onClick={onClick}
      onKeyPress={handleSelect}
      onFocus={onFocus}
    />
  );
});

export default MenuButton;
