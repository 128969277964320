import React, { useState, useEffect } from "react";
import withRouter from "../../../withRouter";
import { Routes, Route } from "react-router-dom";

import api from "../../../services/api";
import Menu from "./Menu";
import Letter from "./Letter";
import PracticePunctuation from "./PracticePunctuation";
import PickPunctuation from "./PickPunctuation";

const ExtraPractice = () => {
  // const { path } = useMatch();
  // const location = useLocation();
  const [loaded, setLoaded] = useState(false);
  const [practice, setPractice] = useState(false);

  useEffect(() => {
    api.getAdditionalPractice().then((response) => {
      console.log(response.data);
      if (response.ok) {
        setPractice(response.data);
        setLoaded(true);
      }
    });
  }, []);
  return (
    <div>
      {loaded ? (
        <Routes>
          <Route index element={<Menu practice={practice} />} />
          <Route path={`/letter`} element={<Letter practice={practice} />} />
          <Route path={`/pick_punctuation`} element={<PickPunctuation practice={practice} />} />
          <Route path={`/practice_punctuation`} element={<PracticePunctuation practice={practice} />} />
        </Routes>
      ) : (
        <div className="center-container">
          <h1>Laden...</h1>
        </div>
      )}
    </div>
  );
};

export default withRouter(ExtraPractice);
