import React, { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Cookies from "js-cookie";
import useSound from "use-sound";

import api from "../../services/api";
import StudentCode from "./StudentCode";
import LoginCode from "./LoginCode";

import SfxAuthorized from "../../assets/sounds/nl/login_yes.mp3";
import SfxUnauthorized from "../../assets/sounds/nl/login_no.mp3";

import "../../assets/styles/global.scss";
import "./authentication.css";

function Authentication() {
  console.warn("Authentication");

  const [playUnauthorized] = useSound(SfxUnauthorized, {
    interrupt: true,
    onend: () => {
      // navigate.replace("/");
    },
  });
  const [playAuthorized] = useSound(SfxAuthorized, {
    interrupt: true,
  });

  const [failedLogin, setFailedLogin] = useState(false);
  const [userCode, setUserCode] = useState("");
  const [loginCode, setLoginCode] = useState("");

  const onChangeUserCode = (value) => {
    setUserCode(value);
  };

  const onChangeLoginCode = (value) => {
    setLoginCode(value);
  };

  const handleLogin = (navigate) => {
    return api.authenticate(userCode, loginCode).then((response) => {
      if (response.ok) {
        const token = response.data.token;
        Cookies.set("__session", token);
        Cookies.set("first_page_after_login", true);
        playAuthorized();
        navigate(0);
        return true;
      } else {
        setFailedLogin(true);
        setUserCode("");
        setLoginCode("");
        playUnauthorized();
        return false;
      }
    });
  };

  const renderStudentCode = () => {
    const isAuthenticated = Cookies.get("__session");
    if (isAuthenticated) {
      return <Navigate to="/" />;
    } else {
      return <StudentCode value={userCode} onChangeValue={onChangeUserCode} />;
    }
  };

  const renderLoginCode = () => {
    const isAuthenticated = Cookies.get("__session");
    if (isAuthenticated) {
      console.log("isAuthenticated");
      return <Navigate to="/" />;
    } else if (failedLogin) {
      return (
        <div className="center-container">
          <h1>Deze combinatie is niet juist</h1>
        </div>
      );
    } else {
      if (userCode !== "") {
        return <LoginCode value={loginCode} onChangeValue={onChangeLoginCode} handleLogin={handleLogin} />;
      } else {
        return <Navigate to="/login/student_code" />;
      }
    }
  };

  return (
    <div>
      <Routes>
        <Route path="student_code" element={renderStudentCode()} />
        <Route path="login_code" element={renderLoginCode()} />
      </Routes>
    </div>
  );
}

export default Authentication;
